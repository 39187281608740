import React, {useContext, useEffect, useState} from 'react'
import Layout from '../components/layout'
import UserContext from '../context/UserContext'
import {navigate} from 'gatsby'
import '../css/pages/auth.scss'
import {api} from '@cg-squad/ui-components/dist/utils';
import {isBrowser} from '../utils/articleUtil';
import * as queryString from "query-string";

function AuthPage (props) {

  const userContext = useContext(UserContext);
  const [openPhoneVerification, setOpenPhoneVerification] = useState(false)
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let next = '/';
    debugger;
    if (isBrowser()) {
      const params = queryString.parse(window.location.search);
      next = params?.next || next;
    }
    if (userContext.user) {
      /*if (!userContext.user.phone_verified) {
        setOpenPhoneVerification(true);
        api.post(`users/${userContext.user?._id}/startPhoneVerification`, {phone: userContext.user?.phone})
      } else {
        navigate(next);
      }*/
      navigate(next);
    } else {
      openLogin(next);
    }
  }, [])

  const openLogin = (next) => {
    userContext.updateState('loginFrom', 'auth-page');
    userContext.updateState('afterLoginNext', next);
    userContext.updateState('openLogin', true);
  }

  const validateForm = () => {
    setError(null);
    if (!otp || otp.trim() === '') {
      setError('Please enter valid verification code');
      return false;
    }

    return true;
  }

  const onVerification = () => {
    userContext.updateState('user', Object.assign(userContext.user, {phone_verified: true}));
    navigate('/chat');
  }

  const verifyPhone = async () => {
    setLoading(true);
    const response = await api.post(`users/${userContext.user?._id}/verifyPhone`, {code: otp})
    console.log(response);
    setLoading(false);
    if (response) {
      onVerification();
    } else {
      setError("Invalid!!")
    }
  }

  const submit = () => {
    if(validateForm()) {
      verifyPhone();
    }
  }

  return (
    <Layout>
      <main className="wrapper main-data-container" role="main" data-datocms-noindex>
        <div className={'lg:bg-login bg-center bg-no-repeat min-h-[450px] lg:min-h-[500px] flex items-center justify-center lg:justify-end'}>
          <div id="login-container" className={"shadow-xl rounded-lg"}>
            <div className={"w-[120px] h-[80px] flex justify-center items-center"}>
              <div className="lds-ellipsis w-full h-full">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              </div>
            </div>
            {openPhoneVerification && <div className={"lg:w-[405px] bg-white p-4 lg:p-8 rounded-lg"}>
              <h4>Verify your mobile phone</h4>
              <p className={"text-13"}>
                We have sent an OTP code to {userContext.user?.phone} mobile that you provided. Please enter the code here.
              </p>
              <div className={`text-red error-message ${error ? 'block opacity-100' : 'hidden lg:block opacity-0'}`}>{error} &nbsp;</div>
              <input type="number"
                     className={'mb-2 w-full p-2 rounded-lg border-black border-default'}
                     placeholder="Enter verification code"
                     value={otp}
                     onChange={(event) => {
                       setError(null)
                       setOtp(event.target.value)
                     }}/>
              <div className={'flex mt-1 xs:mt-4'}>
                <button onClick={submit}
                        className={'ok bg-denim py-3 text-white flex-1 rounded-lg xs:py-2 mb-2 w-full'} disabled={loading}>
                  Verify
                  {loading && (
                    <img
                      src="/images/loading.svg"
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </button>
              </div>
            </div>}
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default AuthPage
